import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedTreatment : null
}

const treatmentSlice = createSlice({
    name: "treatmentSlice",
    initialState,
    reducers: {
        setSelectedTreatment: (state, action) => {
            state.selectedTreatment = action.payload;
        }
    }
});

export default treatmentSlice.reducer;
export const { setSelectedTreatment } = treatmentSlice.actions;