import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { BiSolidCoupon } from "react-icons/bi";
import YellowCoupon from './Coupons/YellowCoupon';
import BlackCoupon from './Coupons/BlackCoupon';
import CouponModal from './CouponModal';
import { getCoupons } from '../../utils/couponUtils/couponUtils';
import { useSelector } from 'react-redux';

const CouponsMainSection = () => {
    const [open, setOpen] = useState(false);
    const [editData,setEditData] = useState(null);
    const [coupons,setCoupons] = useState([]);
    const [category,setCategory] = useState("All");
    const setCouponsFn = useCallback(() => {
      getCoupons()
        .then((res) => setCoupons(res.data.data))
        .catch((err) => console.log(err));
    }, []);
    useEffect(() => {
      setCouponsFn();
    }, [setCouponsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCoupons, setFileteredCoupons] = useState([]);
  useEffect(() => {
    const filteredData = coupons?.filter((coupon) => {
      const idMatch = coupon?.coupon_code?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      if (category === "Consultation") {
        return idMatch && coupon.category === "Consultation";
      } else if (category === "Lab") {
        return idMatch && coupon.category === "Lab";
      } else if (category === "Pharmacy") {
        return idMatch && coupon.category === "Pharmacy";
      } else {
        return idMatch;
      }
    });
    setFileteredCoupons(filteredData);
  }, [coupons, searchQuery, category]);
  const user = useSelector((state) => state.userReducer.admin);
  return (
    <>
      {open && (
        <CouponModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setCouponsFn={setCouponsFn}
        />
      )}
      <div className=" flex w-full items-center gap-5 md:gap-0 ">
        <div className="flex w-fit h-fit text-xl font-semibold">Coupons</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Coupon code"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full h-fit mt-3 mb-3 justify-between">
        <div className="flex w-fit">
          <>
            <select
              className=" appearance-none w-full bg-white border text-[14px] rounded py-2 px-4 outline-none shadow-md"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              {["All", "Consultation", "Lab", "Pharmacy"]?.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </>
        </div>
        <div  className="flex w-fit">
          <button
            disabled={!user.access.includes("Edit")}
            onClick={() => {
              setOpen(true);
            }}
            className={`px-3 py-1 ${user.access.includes("Edit") ? "flex" : "hidden"}  gap-2 rounded-md bg-primary items-center text-white  `}
          >
            <BiSolidCoupon />
            <span>Coupon</span>
          </button>
        </div>
      </div>
      {filteredCoupons.length > 0 ? (
        <div className=" w-full  h-fit mt-5  gap-5  grid grid-cols-1 lg:grid-cols-2 pb-5">
          {filteredCoupons?.map((coupon, index) =>
            index % 2 === 0 ? (
              <YellowCoupon
                key={index}
                data={coupon}
                setEditData={setEditData}
                setOpen={setOpen}
                setModalOpen={setOpen}
                setCouponsFn={setCouponsFn}
                user={user}
              />
            ) : (
              <BlackCoupon
                key={index}
                data={coupon}
                setEditData={setEditData}
                setOpen={setOpen}
                setModalOpen={setOpen}
                setCouponsFn={setCouponsFn}
                user={user}
              />
            )
          )}
        </div>
      ) : (
        <NoDataFound data={"coupons"} />
      )}
    </>
  );
}

export default CouponsMainSection