import { axiosAuthorized } from "../../api/config";

export const getCallUsPoints = async () => {
    try {
        const response = await axiosAuthorized.get("/getcallus");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addCallUsPoint = async (initialState, homepage_icon, id = null) => {
    try {
        const requestData = {
            ...initialState,
            homepage_icon 
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = id
            ? `/callus/update_callus/${id}`
            : `/callus/add_callus `;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteCallUsPoint = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/callus/delete_callus/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}