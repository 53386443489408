import { axiosAuthorized } from "../../api/config";

export const getPrivacyAndPolicy = async () => {
  try {
    const response = await axiosAuthorized.get("/getprivaypolicy");
    return response;
  } catch (error) {
    throw error;
  }
}

export const addPolicy = async (description, section, id = null) => {
  try {
    const url = id ? `/policy/update_policy/${id}` : `/policy/add_policy`;
    const response = await axiosAuthorized[id ? 'put': 'post'](url, {
      description,
      section
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const getTermAndConditions = async () => {
  try {
    const response = await axiosAuthorized.get("/gettermscondition");
    return response;
  } catch (error) {
    throw error;
  }
}

export const getCancellationReturnPolicy = async () => {
  try {
    const response = await axiosAuthorized.get("/getcancellationpolicy");
    return response;
  } catch (error) {
    throw error;
  }
}