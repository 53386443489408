import React from 'react';
import "./Departments.css";
import { DepartmentsMainSection } from '../../components/DepartmentsComponents';

const Departments = () => {
  return (
    <div className="department-page-container relative">
      <DepartmentsMainSection/>
    </div>
  );
}

export default Departments