import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const MapContents = ({contents,removeContent, setEditData}) => {
  return (
    <>
      {contents.length > 0 && (
        <div className="flex w-full flex-wrap gap-2">
          {contents.map((content, idx) => (
            <div
              onClick={()=>setEditData(content)}
              className="flex gap-2 items-center cursor-pointer border w-fit h-fit rounded-md p-1 shadow-md text-xs"
              key={idx}
            >
              <div className="flex items-center gap-1">
                <div  className={`flex w-7 h-7`}>
                  <img
                    src={
                      content.content_images instanceof File
                        ? URL.createObjectURL(content.content_images)
                        : content.content_images
                    }
                    alt={"content_image"}
                    className={`rounded-full ${content.content_images ? "flex" : "hidden"}`}
                  />
                </div>
                <span>{content.question}</span>
              </div>
              <button
                onClick={() => {
                  removeContent(content._id);
                }}
              >
                <AiOutlineCloseCircle fontSize={14} color="red" />
              </button>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default MapContents