import React, { useCallback, useEffect, useState } from "react";
import { BiImageAdd } from "react-icons/bi";
import { NoDataFound } from "../Common";
import HomeSliderModal from "./HomeSliderModal";
import {
  deleteHomeImage,
  getHomeImages,
} from "../../utils/sliderUtils/homeSliderUtils";
import { MdOutlineDeleteOutline } from "react-icons/md";

const HomePageSlider = () => {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const setImagesFn = useCallback(() => {
    getHomeImages()
      .then((res) => setImages(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setImagesFn();
  }, [setImagesFn]);
  const delImage = (imageId) => {
    deleteHomeImage(imageId)
      .then((res) => {
        setImagesFn();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full items-center justify-center gap-10">
        <span className="font-semibold ">Home Image Slider</span>
        <button
          onClick={() => setOpen(true)}
          className="w-fit h-fit px-3 py-1 bg-primary flex gap-2 rounded-md text-white items-center justify-center"
        >
          <BiImageAdd fontSize={18} />
          <span>Add</span>
        </button>
      </div>
      <div className="flex w-full h-full relative">
        {open && (
          <HomeSliderModal setOpen={setOpen} setHomeImagesFn={setImagesFn} />
        )}
        {images?.length > 0 ? (
          <div className=" flex h-fit items-center w-full flex-col mt-5">
            <div className="flex flex-col w-full mt-3 max-h-[400px]">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-blue-100">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                          >
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 max-h-[400px] overflow-y-auto">
                        {images.map((image, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                <img
                                  src={image?.home_page_slider}
                                  className="w-[100px] h-[80px] rounded-md object-cover"
                                  alt={"home_image"}
                                />
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div
                                className="text-sm text-gray-900 capitalize cursor-pointer"
                                onClick={() => {
                                  delImage(image?._id);
                                }}
                              >
                                <MdOutlineDeleteOutline color="red" />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <NoDataFound data={"home images"} />
        )}
      </div>
    </div>
  );
};

export default HomePageSlider;
