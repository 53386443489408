import { axiosAuthorized } from "../../api/config";

export const getSymtoms = async () => {
    try {
        const response = await axiosAuthorized.get("/getnewsymptoms");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addSymtom = async (initialState, treatId,image,symotomId=null) => {
    try {
        const requestData = {
            name: initialState.name,
            sub_department_id: treatId,
            new_symptom_image: image
        };
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };
        const url = symotomId
            ? `/symptoms/update_newsymptoms/${symotomId}`
            : `/symptoms/add_newsymptoms`;

        const response = await axiosAuthorized[symotomId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteSymtom = async (symotomId) => {
    try {
        const response = await axiosAuthorized.delete(`/symptoms/delete_newsymptoms/${symotomId}`);
        return response;
    } catch (error) {
        throw error;
    }
}