import React, { useEffect, useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import {
  AddButton,
  ImageInput,
  ShowImage,
  TextInput,
} from "../Common/FormComponents";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { validateFooterForm } from "../../utils/homePageFooterUtils/validateFooterForm";
import { addFooterData } from "../../utils/homePageFooterUtils/homePageFooterUitls";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";

const HomePageFooter = ({
  setOpen,
  editData = null,
  setEditData,
  setFooterDataFn,
}) => {
  const initialState = {
    hospital_name: editData?.hospital_name || "",
    hospital_address: editData?.hospital_address || "",
    email: editData?.email || "",
    phone_number: editData?.phone_number || "",
    telephone_number: editData?.telephone_number || "",
  };
//   const linkInitialState = { link: "" };
//   const [linkFormData, handleLinkForm, clearLinkForm] =
//   useForm(linkInitialState);
  const [err, setError] = useState({ global: "" });
//   const [links, setlinks] = useState(editData?.links || []);
  const [social_media_links, setSocailMediaLinks] = useState(editData?.social_media_links || []);
  const socailMedialinkInitialState = { social_media_links: "" };
  const [socialMedialinkFormData, handleSocialMediaLinkForm, clearSocailMediaLinkForm] =
    useForm(socailMedialinkInitialState);
//   const handlelinks = (action, idx = 0) => {
//     if (action === "add") {
//       if (!linkFormData.link.trim().length) {
//         return setError({ global: "Please Enter Link" });
//       } else {
//           setlinks([...links, linkFormData.link]);
//         clearLinkForm();
//       }
//     } else if (action === "remove") {
//       setlinks(links.filter((_, index) => index !== idx));
//     }
//   };
  const handleSocialMedialinks = (action, idx = 0) => {
    if (action === "add") {
      if (!socialMedialinkFormData.social_media_links.trim().length) {
        return setError({ global: "Please Enter Social Media Link" });
      } else {
        setSocailMediaLinks([...social_media_links, socialMedialinkFormData.social_media_links]);
        clearSocailMediaLinkForm();
      }
    } else if (action === "remove") {
      setSocailMediaLinks(social_media_links.filter((_, index) => index !== idx));
    }
  };
  const [department, setDepartments] = useState(editData?.department || []);
  const [deps, setDeps] = useState([]);
  useEffect(() => {
    getDepartments()
      .then((res) => {
        const departments = res.data.data.map((item) => ({
          _id: item._id,
          department: item.department,
        }));
        setDeps([{ _id: null, department: "Select Department" }, ...departments]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [hospital_logo, setHospitalLogo] = useState(
    editData?.hospital_logo || null
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFooterForm(formData, hospital_logo, setError)) {
      setError({ global: "" });
      setLoading(true);
      addFooterData(formData, hospital_logo,social_media_links,department, editData?._id)
        .then((res) => {
          toast(`Footer ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          clearForm();
          setHospitalLogo(null);
          setFooterDataFn();
          setOpen(false);
        })
        .catch((err) => {
          toast(`Footer ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="modal-container min-h-[400px] z-10">
      <div className="modal">
        <div className="modal-header">
          <span>Edit Footer</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Hospital Name"}
            formName={"hospital_name"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Hospital Address"}
            formName={"hospital_address"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Email"}
            formName={"email"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Phone"}
            formName={"phone_number"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Telephone"}
            formName={"telephone_number"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={hospital_logo} setImage={setHospitalLogo} />
              <ImageInput
                image={hospital_logo}
                setImage={setHospitalLogo}
                imageName={"Hospital Logo"}
              />
            </>
          }
        </div>
        {/* {links?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {links?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handlelinks("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={linkFormData}
            handleInput={handleLinkForm}
            name={"Links"}
            formName={"link"}
            setError={setError}
          />
          <AddButton handleAdd={handlelinks} />
        </div> */}
        {social_media_links?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {social_media_links?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleSocialMedialinks("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={socialMedialinkFormData}
            handleInput={handleSocialMediaLinkForm}
            name={"Social Media Links"}
            formName={"social_media_links"}
            setError={setError}
          />
          <AddButton handleAdd={handleSocialMedialinks} />
        </div>
        {department?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {department?.map((item, index) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={item._id}
              >
                <span>{item.department}</span>
                <button
                  onClick={() => {
                    const updatedDepartments = [...department];
                    updatedDepartments.splice(index, 1);
                    setDepartments(updatedDepartments);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <select
            className="select-option"
            onChange={(e) => {
              const selectedDepartmentId = e.target.value;
              const selectedDepartment = deps.find(
                (department) => department.department === selectedDepartmentId
              );
              setDepartments((prev) => [
                ...prev,
                {
                  _id: selectedDepartment._id,
                  department: selectedDepartment.department,
                },
              ]);
            }}
          >
            {deps?.map((item, index) => (
              <option key={index} value={item.department}>
                {item.department}
              </option>
            ))}
          </select>
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageFooter;
