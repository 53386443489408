import React, { useCallback, useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { getCancellationReturnPolicy } from "../../utils/privacyUtils/privacyUtils";
import CancellationReturnPolicyModal from "./CancellationReturnPolicyModal";

const CancellationReturnPolicy = () => {
  const [open, setOpen] = useState(null);
  const [data, setDatas] = useState(null);
  const setDatasFn = useCallback(() => {
    getCancellationReturnPolicy()
      .then((res) => {
        setDatas(res?.data?.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => setDatasFn(), [setDatasFn]);
  const [editData, setEditData] = useState(null);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative mt-5 ">
      {open && (
        <CancellationReturnPolicyModal
          setDatasFn={setDatasFn}
          setEditData={setEditData}
          setOpen={setOpen}
          editData={editData}
        />
      )}
      <div className="flex w-full justify-start gap-2 items-center h-fit ">
        <span className="font-semibold">Cancellation Return Policy</span>
        <button
          onClick={() => {
            setEditData(data);
            setOpen(true);
          }}
          className="px-3 flex gap-2 items-center  bg-primary rounded-md text-white py-1"
        >
          <BsPlus fontSize={18} />
          <span>Update</span>
        </button>
      </div>
    </div>
  );
};

export default CancellationReturnPolicy;
