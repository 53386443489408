import { axiosAuthorized } from "../../api/config";

export const getPharmacies = async () => {
    try {
        const response = await axiosAuthorized.get("/member/get_phrammember");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addPharmacy = async (initialState, image, pharmacyId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            owner_name: initialState.owner,
            email_id: initialState.email,
            phone_number: initialState.phone,
            department: initialState.department,
            category: initialState.category,
            profile_image: image
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = pharmacyId
            ? `/member/update_phram/${pharmacyId}`
            : `/member/add_phrammember`;

        const response = await axiosAuthorized[pharmacyId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deletePharmacy = async (pharmacyId) => {
    try {
        const response = await axiosAuthorized.delete(`/member/delete_phrammember/${pharmacyId}`);
        return response;
    } catch (error) {
        throw error;
    }
}