export const validateFaqForm = (initialState, setError) => {
    if (!initialState.title.trim().length) {
        setError({ faq: "Invalid Question" });
        return false;
    }
    if (!initialState.answer.trim().length) {
        setError({ faq: "Invalid Answer" });
        return false;
    }
    return true;

}