import React from "react";
import { CareerMainSection } from "../../components/CareerComponents";


const Career = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
    <CareerMainSection/>
    </div>
  );
};

export default Career;
