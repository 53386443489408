import React, { useCallback, useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { getCountsAndDoctorImage } from '../../utils/countsAndDoctorImageUtils/countsAndDoctorImageUtils';
import CountsAndDoctorImageModal from './CountsAndDoctorImageModal';

const CountsAndDoctorImage = () => {
    const [open, setOpen] = useState(null);
    const [countsAndDoctorImage, setCountsAndDoctorImage] = useState(null);
    const setCountsAndDoctorImageFn = useCallback(() => {
        getCountsAndDoctorImage().then(res => { setCountsAndDoctorImage(res)}).catch(err => console.log(err))
    }, []);
    useEffect(() => setCountsAndDoctorImageFn(), [setCountsAndDoctorImageFn]);
    const [editData, setEditData] = useState(null);
    return (
      <div className="w-full h-fit flex flex-col gap-5 relative mt-5 ">
        {open && (
          <CountsAndDoctorImageModal
            setCallUsFn={setCountsAndDoctorImageFn}
            setEditData={setEditData}
            setOpen={setOpen}
            editData={editData}
          />
        )}
        <div className="flex w-full justify-start gap-2 items-center h-fit ">
          <span className="font-semibold">Call Us</span>
          <button
            onClick={() => {
              setEditData(countsAndDoctorImage);
              setOpen(true);
            }}
            className="px-3 flex gap-2 items-center  bg-primary rounded-md text-white py-1"
          >
            <BsPlus fontSize={18} />
            <span>Edit</span>
          </button>
        </div>
      </div>
    );
}

export default CountsAndDoctorImage;