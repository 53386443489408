import React, { useCallback, useEffect, useState } from 'react'
import { deleteInsuranceProvider, getInsuranceProviders } from '../../utils/insuranceProviderUtils/insuranceProviderUtils';
import { BsPlus } from 'react-icons/bs';
import { CiEdit } from 'react-icons/ci';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { NoDataFound } from '../Common';
import InsuranceProviderModal from './InsuranceProviderModal';

const InsuranceProviders = () => {
  const[open, setOpen] = useState(false);
  const [providers, setProviders] = useState([]);
  const setProvidersFn = useCallback(() => {
    getInsuranceProviders()
      .then((res) => setProviders(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setProvidersFn();
  }, [setProvidersFn]);
  const delProvider = (providerId) => {
    deleteInsuranceProvider(providerId)
      .then((res) => {
        setProvidersFn();
      })
      .catch((err) => console.log(err));
  };
  const [editData, setEditData] = useState(null);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative mt-5 pb-5">
      {open && (
        <InsuranceProviderModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setProvidersFn={setProvidersFn}
        />
      )}
      <div className="flex w-full justify-start gap-2 items-center h-fit">
        <span className="font-semibold">Providers</span>
        <button
          onClick={() => setOpen(true)}
          className="px-3 flex gap-2 items-center  bg-primary rounded-md text-white py-1"
        >
          <BsPlus fontSize={18} />
          <span>Add</span>
        </button>
      </div>
      {providers?.length > 0 ? (
        <div className=" flex h-fit items-center w-full flex-col ">
          <div className="flex flex-col w-full mt-3 max-h-[400px]">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-blue-100">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Link
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Edit
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 max-h-[400px] overflow-y-auto">
                      {providers.map((provider, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              <img
                                src={provider?.insurance_image}
                                className="w-[100px] h-[80px] rounded-md object-cover"
                                alt={"insurance_image"}
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {provider?.name}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {provider?.link}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className="text-sm text-gray-900 capitalize cursor-pointer"
                              onClick={() => {
                                setEditData(provider);
                                setOpen(true);
                              }}
                            >
                              <CiEdit color="black" />
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div
                              className="text-sm text-gray-900 capitalize cursor-pointer"
                              onClick={() => {
                                delProvider(provider?._id);
                              }}
                            >
                              <MdOutlineDeleteOutline color="red" />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoDataFound data={"providers"} />
      )}
    </div>
  );
}

export default InsuranceProviders