import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';

const MapPackages = ({packages,removePackage}) => {
  return (
    <div>
      {packages.length > 0 && (
        <div className="flex w-full flex-wrap gap-2">
          {packages.map((item, idx) => (
            <div
              className="flex gap-2 items-center border w-fit h-fit rounded-md p-1 shadow-md text-xs"
              key={idx}
            >
              <div className="flex items-center gap-1">
                <div className="flex w-7 h-7">
                  <img
                    src={
                      item.image instanceof File
                        ? URL.createObjectURL(item.image)
                        : item.image
                    }
                    alt={"doctor_image"}
                    className="rounded-full"
                  />
                </div>
                <span>{item.package_name}</span>
              </div>
              <button onClick={() => removePackage(item._id)}>
                <AiOutlineCloseCircle fontSize={14} color="red" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MapPackages