export const validateFooterForm = (initialState, image, setErr) => {
    const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    const numberPattern = /^[0-9]+$/;
    if (!initialState.hospital_name.trim().length) {
        setErr({ global: "Invalid Name" });
        return false;
    }

    if (!initialState.hospital_address.trim().length) {
        setErr({ global: "Invalid Address" });
        return false;
    }

    if (!emailPattern.test(initialState.email)) {
        setErr({ global: "Invalid Email" });
        return false;
    }

    if (!numberPattern.test(initialState.phone_number)) {
        setErr({ global: "Invalid Phone" });
        return false;
    }

    if (!numberPattern.test(initialState.telephone_number)) {
        setErr({ global: "Invalid Telephone" });
        return false;
    }

    if (!image) {
        setErr({ global: "Select Profile Image" });
        return false;
    }

    return true;
}