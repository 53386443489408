import React, { useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import {
    AddButton,
  ImageInput,
  ShowImage,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { validateContactUsForm } from "../../utils/contactUsUtils/validateContactUsFrom";
import { addContactUs } from "../../utils/contactUsUtils/contactUsUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";

const ContactUsModal = ({
  setOpen,
  editData = null,
  setEditData,
  setContactUsFn,
}) => {
  const initialState = {
    banner_title: editData?.banner_title || "",
    banner_description: editData?.banner_description || "",
  };
  const [err, setError] = useState({ global: "" });
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [banner_images, setBannerImage] = useState(editData?.banner_images || null);
  const [hospital_location, setHospitalLocations] = useState(editData?.hospital_location || []);
  const hospitalLocationInitialState = { location_name: "", location_email:"", location_call:"" };
  const [hospitalLocationFromData, handleHospitalLocationFormData, clearHospitalLocationForm] =
      useForm(hospitalLocationInitialState);
    const handleHospitalLocations = (action, idx = 0) => {
      if (action === "add") {
        if (
          !hospitalLocationFromData.location_name.trim().length ||
          !hospitalLocationFromData.location_email.trim().length ||
          !hospitalLocationFromData.location_call.trim().length
        ) {
          return setError({ global: "Please Enter Location Details" });
        } else {
          setHospitalLocations([
            ...hospital_location,
            {
              location_call: hospitalLocationFromData.location_call,
              location_name: hospitalLocationFromData.location_name,
              location_email: hospitalLocationFromData.location_email,
            },
          ]);
          clearHospitalLocationForm();
        }
      } else if (action === "remove") {
        setHospitalLocations(hospital_location.filter((_, index) => index !== idx));
      }
    };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateContactUsForm(formData,hospital_location, banner_images, setError)) {
      setError({ global: "" });
      setLoading(true);
      addContactUs(formData,hospital_location, banner_images, editData?._id)
        .then((res) => {
          toast(`Contact us ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          clearForm();
          setBannerImage(null);
          setContactUsFn();
          setOpen(false);
        })
        .catch((err) => {
          toast(`Contact us ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="modal-container min-h-[400px] z-10">
      <div className="modal">
        <div className="modal-header">
          <span>Edit Contact Us</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Title"}
            formName={"banner_title"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <TextAreaInput
            data={formData}
            handleInput={handleInput}
            name={"Description"}
            formName={"banner_description"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={banner_images} setImage={setBannerImage} />
              <ImageInput
                image={banner_images}
                setImage={setBannerImage}
                imageName={"Banner Image"}
              />
            </>
          }
        </div>
        {hospital_location?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {hospital_location?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item.location_name}</span>
                <button
                  onClick={() => {
                    handleHospitalLocations("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer gap-x-3">
          <TextInput
            data={hospitalLocationFromData}
            handleInput={handleHospitalLocationFormData}
            name={"Name"}
            formName={"location_name"}
            setError={setError}
          />
          <TextInput
            data={hospitalLocationFromData}
            handleInput={handleHospitalLocationFormData}
            name={"Email"}
            formName={"location_email"}
            setError={setError}
          />
          <TextInput
            data={hospitalLocationFromData}
            handleInput={handleHospitalLocationFormData}
            name={"Call"}
            formName={"location_call"}
            setError={setError}
          />
          <AddButton handleAdd={handleHospitalLocations} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;
