import React, {  useMemo } from "react";
import "./DoctorsTable.css";
import { TbUserEdit } from "react-icons/tb"
import { NoDataFound } from "../Common";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteDoctor } from "../../utils/doctorUtils/doctorUtils";

const DoctorsInformation = ({setEditData,setOpen,doctors,setDoctorsFn, user}) => {
  const delDoctor = (doctorId) => {
    deleteDoctor(doctorId).then(res=> setDoctorsFn()).catch(err=>console.log(err));
  };
  const tableHeadings = useMemo(
    () => {
      const defaultHeadings = ["Name", "Doctor Id", "Gender", "Experience"];
      const userHasEditAccess = user?.access?.includes("Edit");
      const userHasDeleteAccess = user?.access?.includes("Delete");

      const headings = [...defaultHeadings];

      if (userHasEditAccess) {
        headings.push("Edit");
      }

      if (userHasDeleteAccess) {
        headings.push("Delete");
      }
      return headings;
    },
    [user]
  );
  return (
    <div className="doctors-information-container flex-col mt-5">
      <div className="di-table-container max-h-screen">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {doctors.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th scope="col" key={index} className="di-head-row">
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-screen overflow-y-auto">
                    {doctors.map((doctor, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                              <img
                                className="rounded-full h-9 w-9 object-cover"
                                src={doctor?.doctor_image}
                                alt="doctor_profile"
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              {doctor?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {doctor?.doctor_id}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {doctor?.gender}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {doctor?.experience} <span>Year</span>
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Edit") && "hidden"
                          } px-6 py-4 whitespace-nowrap `}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              setEditData(doctor);
                              setOpen(true);
                            }}
                          >
                            <TbUserEdit />
                          </div>
                        </td>
                        <td
                          className={`${
                            !user?.access.includes("Delete") && "hidden"
                          } px-6 py-4 whitespace-nowrap`}
                        >
                          <div
                            className="text-sm text-gray-900 capitalize cursor-pointer"
                            onClick={() => {
                              delDoctor(doctor?._id);
                            }}
                          >
                            <MdOutlineDeleteOutline color="red" />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={"doctors"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorsInformation;
