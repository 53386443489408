import React, { useState } from "react";
import ReactECharts from "echarts-for-react";

const formatNumber = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(1) + "k";
  } else {
    return (num / 1000000).toFixed(1) + "m";
  }
};

const WebsiteSection = () => {
  const [view, setView] = useState("weekly");

  const categoriesWeekly = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const categoriesMonthly = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const websiteVisits =
    view === "weekly"
      ? [100, 100, 100, 100, 100, 100, 100]
      : [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100];
  const userRegistrations =
    view === "weekly"
      ? [800, 600, 400, 500, 900, 850, 700]
      : [800, 600, 400, 500, 900, 850, 700, 800, 600, 400, 500, 900, 850, 700];

  const getCategories = () => {
    return view === "monthly" ? categoriesMonthly : categoriesWeekly;
  };

  const option = {
    xAxis: {
      type: "category",
      data: getCategories(),
      axisLabel: {
        textStyle: {
          color: "white",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#999",
        },
      },
      axisTick: {
        show: false,
      },
      position: "top",
      splitLine: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "",
        axisLabel: {
          formatter: "{value}%",
          textStyle: {
            color: "white",
          },
        },
      },
      {
        type: "value",
        name: "",
        axisLine: {
          lineStyle: {
            color: "whtie",
          },
        },
      },
    ],
    series: [
      {
        name: "Website Visits",
        type: "bar",
        barWidth: "100%",
        data: websiteVisits,
        itemStyle: {
          color: function (params) {
            return params.dataIndex % 2 === 0 ? "#1C315C" : "#325087";
          },
        },
      },
      {
        name: "",
        type: "line",
        yAxisIndex: 1,
        data: userRegistrations,
        lineStyle: {
          color: "#5694FB",
          width: 5,
          shadowColor: "#1C315C",
          shadowBlur: 3,
          shadowOffsetY: 2,
        },
        symbol: "none",
        areaStyle: {
          color:
            "linear-gradient(115deg, rgba(7, 0, 84, 0.85) -61.32%, rgba(0, 3, 29, 0.00) 133.7%)",
        },
      },
    ],
  };

  return (
    <div>
      <div className="flex w-full h-fit justify-between items-center px-3 py-2 rounded-t-md border-b bg-[#1C315C] border-[#999]">
        <span className="text-white text-base">Webstie views</span>
        <span className="lg:hidden text-xl font-black  text-white flex">
          {formatNumber(1000)} - AVG
        </span>
        <select
          className="bg-[#325087] rounded-md text-white outline-none text-sm"
          value={view}
          onChange={(e) => setView(e.target.value)}
        >
          <option value="weekly">Week</option>
          <option value="monthly">Month</option>
        </select>
      </div>
      <div className="w-full rounded-b-md overflow-hidden bg-[#1C315C]  h-fit">
        <div className="float-left w-[300px] lg:flex h-[450px] hidden flex-col gap-y-5 items-center justify-center bg-[#325087] text-white">
          <span className="w-full text-6xl font-black text-center">
            {formatNumber(1000)}
          </span>
          <span className="w-full text-3xl font-black text-center">AVG</span>
        </div>
        <div className="overflow-hidden">
          <ReactECharts option={option} style={{ height: "450px" }} />
        </div>
      </div>
    </div>
  );
};

export default WebsiteSection;
