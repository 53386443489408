import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import { Dashboard, Doctors, Departments, Treatments, Labs, Pharmacies, Coupons, Media, Login, Admins, Blogs, Leads, About, Career } from "./pages";
import { UserProtected,AuthProtected } from "./components/ProtectedRoutes/UserProtected";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";


function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const admin = JSON.parse(localStorage.getItem("admin"));
    if (admin) {
      dispatch(saveUser(admin));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  const user = useSelector((state) => state.userReducer.admin);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Dashboard />} />
        <Route path="doctors" element={<Doctors/>} />
        <Route path="departments" element={<Departments/>} />
        <Route path="treatments" element={<Treatments/>} />
        <Route path="labs" element={<Labs/>} />
        <Route path="pharmacies" element={<Pharmacies/>} />
        <Route path="coupons" element={<Coupons/>} />
        <Route path="media" element={<Media />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="leads" element={<Leads />} />
        <Route path="about" element={<About />} />
        <Route path="career" element={<Career />} />
        <Route path="admins" element={user?.is_master ? <Admins /> : <Navigate to={"/"}/>} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login/>}/>} />
    </Routes>
  );
}

export default App;

