import { axiosAuthorized } from "../../api/config";

export const getLabs = async () => {
    try {
        const response = await axiosAuthorized.get("/member/get_labmember");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addLab = async (initialState,image, labId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            owner_name: initialState.owner,
            email_id: initialState.email,
            phone_number: initialState.phone,
            department: initialState.department,
            category: initialState.category,
            lab_profile_image: image
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = labId
            ? `/member/update_labmember/${labId}`
            : `/member/add_labmember`;

        const response = await axiosAuthorized[labId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteLab = async (labId) => {
    try {
        const response = await axiosAuthorized.delete(`/member/delete_labmember/${labId}`);
        return response;
    } catch (error) {
        throw error;
    }
}