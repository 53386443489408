import { axiosAuthorized } from "../../api/config"

export const getCoupons = async () => {
    try {
        const response = await axiosAuthorized.get("/coupon/get_coupon");
        return response;
    } catch (error) {
        throw error;
    }
}


export const addCoupon = async (initialState, couponId = null) => {
    try {
        const requestData = {
            category: initialState.category,
            coupon_date: initialState.date,
            coupon_amount: initialState.amount,
            coupon_percentage: initialState.percentage,
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url = couponId
            ? `/coupon/update_coupon/${couponId}`
            : `/coupon/add_coupon`;

        const response = await axiosAuthorized[couponId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteCoupon = async (couponId) => {
    try {
        const response = await axiosAuthorized.delete(`/coupon/delete_coupon/${couponId}`);
        return response;
    } catch (error) {
        throw error;
    }
}  