export const validatePackageForm = (initialState,benefits, image, setError) => {
    const pricePattern = /^\d+(\.\d{1,2})?$/;
    
    if (!initialState.name.trim().length) {
        setError({ package: "Invalid Name" });
        return false;
    }
    if (!benefits.replace(/<[^>]*>/g, '').trim().length) {
        setError({ package: "Invalid Benefits" });
        return false;
    }
    if (!initialState.duration.trim().length) {
        setError({ package: "Invalid Duration" });
        return false;
    }
    if (!pricePattern.test(initialState.price)) {
        setError({ package: "Invalid Price" });
        return false;
    }
    if (!image) {
        setError({ package: "Select Image" });
        return false;
    }
    return true;

}