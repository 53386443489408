export const vaidateTreatmentForm = (initialState, description, department_id,symptoms, meta_tag, setError) => {

    if (!initialState.name.trim().length) {
        setError({ global: "Invalid Name" });
        return false;
    }

    if (department_id === 0) {
        setError({ global: "Please Select Department" });
        return false;
    }
    if (!description.replace(/<[^>]*>/g, '').trim().length) {
        setError({ content: "Invalid Description" });
        return false;
    }
    if (!initialState.meta_name.trim().length) {
        setError({ global: "Invalid Meta Name" });
        return false;
    }
    if (!initialState.meta_description.trim().length) {
        setError({ global: "Invalid Meta Description" });
        return false;
    }
    if (!symptoms.length) {
        setError({ global: "Please Add At Least One Symptom" });
        return false;
    }
    if (!meta_tag.length) {
        setError({ global: "Please Add At Least One Meta Tag" });
        return false;
    }
    return true;

}