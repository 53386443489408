import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { ImageInput, ShowImage } from '../Common/FormComponents';
import { addHomeImage } from '../../utils/sliderUtils/homeSliderUtils';
import { toast } from 'react-toastify';

const HomeSliderModal = ({ setOpen, setHomeImagesFn }) => {
    const [err, setErr] = useState({ global: "" });
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
      if (!image) return setErr({ global: "Select Image" });
      setErr({ global: "" });
      setLoading(true);
      addHomeImage(image)
        .then(res => {
        setHomeImagesFn();
        setImage(null);
        setOpen(false);
        toast(`Home image added`, {
          type: "success",
          autoClose: 3000,
        });
        })
        .catch(err => {
          console.log(err);
          toast(`Home image adding failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => setLoading(false));
    }
  return (
    <div className="flex z-10 absolute w-full h-full  rounded-md justify-start items-start bg-white">
      <div className=" flex bg-white w-full overflow-y-auto h-fit border shadow-lg  rounded-sm gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Add Home Image"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={image} setImage={setImage} />
          <ImageInput imageName={"Home Image"} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setImage(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSliderModal