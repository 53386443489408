import React from 'react'
import LabsMainSection from "../../components/LabsComponents/LabsMainSection"

const Labs = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <LabsMainSection />
    </div>
  );
}

export default Labs