import React from "react";
import "./Treatments.css";
import { TreatmensMainSection } from "../../components/TreatmentsComponents";


const Treatments = () => {
  return (
      <div className="treatment-page-container relative">
          <TreatmensMainSection/>
    </div>
  );
};

export default Treatments;
