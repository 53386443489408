import { axiosAuthorized } from "../../api/config";

export const addHomeFaq = async (initialState,faqId = null) => {
    try {
        const requestConfig = {
            headers: { "Content-Type": "application/json" }
        }
        const requestData = {
            faq_title: initialState.title,
            faq_answer: initialState.answer,
        }
        const url = faqId ? `/homepage/update_faq/${faqId}` : `/homepage/add_faq`;
        const response = await axiosAuthorized[faqId ? 'put' : 'post'](url,requestData,requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}


export const getHomeFaqs = async () => {
    try {
        const response = await axiosAuthorized.get(`/homepage/get_faq`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const deleteHomeFaq = async (faqId) => {
    try {
        const response = await axiosAuthorized.delete(`/homepage/delete_faq/${faqId}`);
        return response;
    } catch (error) {
        throw error;
    }
}