export const validateBlogForm = (initialState,description, dpId, blogs_faq, blog_image, setErr) => {

    
    if (!initialState.blog_title.trim().length) {
        setErr({ global: "Invalid Title" });
        return false;
    }
    if (dpId === 0) {
        setErr({ global: "Please Select Department" });
        return false;
    }
    if (!description.replace(/<[^>]*>/g, '').trim().length) {
        setErr({ content: "Invalid Content" });
        return false;
    }
    return true;
}