import React, { useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { useForm } from '../../hooks';
import { DateInput, SelectInput, TextInput } from '../Common/FormComponents';
import { validateCouponData } from '../../utils/couponUtils/validateCouponForm';
import { addCoupon } from '../../utils/couponUtils/couponUtils';
import { toast } from 'react-toastify';

const CouponModal = ({ setOpen, editData = null,setEditData, setCouponsFn }) => {
    const couponInitailState = {
      date: editData?.coupon_date || "",
      category: editData?.category || "",
      amount: editData?.coupon_amount || "",
      percentage: editData?.coupon_percentage || "",
    };
    const [couponData, handleCouponData, clearForm] = useForm(couponInitailState);
    const [err, setErr] = useState({ global: "" });
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
      if (validateCouponData(couponData, setErr)) {
        setErr({ global: "" });
        setLoading(true);
        addCoupon(couponData, editData?._id)
          .then(res => {
            setErr({ global: "" }); setCouponsFn(); clearForm(); setOpen(false);
            toast(`Coupon ${editData ? "Edited" : "Added"}`, {
              type: "success",
              autoClose: 3000,
            });
            setEditData(null);
          })
          .catch(err => {
            toast(`Coupon ${editData ? "edit" : "add"} failed`, {
              type: "error",
              autoClose: 3000,
            });
          })
          .finally(() => { setLoading(false) });
        }
    }
  return (
    <div className="absolute flex w-full h-full top-0 left-0 bottom-0 justify-center items-start p-4 z-50 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-fit mt-6 border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
            <span>{"Add new Coupon"}</span>
            <span className="cursor-pointer" onClick={() => { setOpen(false);setEditData(null); }}><GrFormClose fontSize={16}/></span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
        <SelectInput name={"Category"} items={["Select Category","Consultaion","Lab","Pharmacy"]} formName={"category"} data={couponData} handleInput={handleCouponData} setError={setErr}/>
        <DateInput name={"Date"} formName={"date"} data={couponData} handleInput={handleCouponData} setError={setErr}/>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
        <TextInput name={"Amount"} formName={"amount"} data={couponData} handleInput={handleCouponData} setError={setErr}/>
        <TextInput name={"Percentage"} formName={"percentage"} data={couponData} handleInput={handleCouponData} setError={setErr}/>
        </div>
        {err.global && (<div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.global}</div>)}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center" onClick={() => { setOpen(false); setEditData(null);  }}>
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center " disabled={loading} onClick={(e) => {handleSubmit(e);}}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CouponModal