import React, { useMemo, useState } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { ImageInput, ShowImage, TextInput } from "../Common/FormComponents";
import { useForm } from '../../hooks';
import { validateAdminForm } from '../../utils/adminUtils/validateAdminForm';
import { addAdmin } from '../../utils/adminUtils/adminUtils';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const AddAdminModal = ({ editData = null, setOpen, setEditData, setAdminsFn }) => {
        const [err, setErr] = useState({ global: "" });
        const [image, setImage] = useState(editData?.admin_photo || null);
        const initialState = {
          name: editData?.name || "",
          contact: editData?.phone_number || "",
          email: editData?.email_id || "",
          department: editData?.department || "",
          category: editData?.category || "",
        };
        const [pages,setPages] = useState(editData?.pages || []);
        const handleSelectedPages = (event) =>{
          const page = event.target.value;
          if (!pages.includes(page)) {
            setPages([...pages, page]);
          }
        }
        const currentPages = useMemo(() => [
          "Analytics",
          "Doctors",
          "Departments",
          "Treatments",
          "Labs",
          "Pharmacies",
          "Coupons",
          "Media",
          "Blogs",
          "Leads",
          "About",
          "Career"
        ],[]);
        const [access,setAccess] = useState(editData?.access || []);
        const handleAccess = (event) => {
          const acces = event.target.value;
          if (!access.includes(acces)) {
            setAccess([...access, acces]);
          }
        };
        const currentAccess = useMemo(
          () => [
            "Edit","Delete"
          ],
          []
        );
        const [adminData, handleAdminData] = useForm(initialState);
        const [loading,setLoading] = useState(false);
        const handleSubmit = (e) => {
          e.preventDefault();
          if (validateAdminForm(adminData,pages, image, setErr, editData?.is_master)) {
            setErr({ global: "" });
            setLoading(true);
            addAdmin(adminData,pages,access, image, editData?._id)
              .then(res => { setAdminsFn(); setEditData(null); setImage(null); setOpen(false); })
              .catch(err => {console.log(err)})
              .finally(() => setLoading(false));
          }
        };
  return (
    <div className="absolute flex w-full h-fit overflow-y-auto top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div className="flex bg-white w-full max-w-screen-md overflow-y-auto h-full border shadow-lg  rounded-md gap-5 px-4 pb-4 pt-2 flex-col">
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{editData ? "Edit Admin" : "Add New Admin"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            name={"Name"}
            formName={"name"}
            data={adminData}
            handleInput={handleAdminData}
            setError={setErr}
          />
          <TextInput
            name={"Contact"}
            formName={"contact"}
            data={adminData}
            handleInput={handleAdminData}
            setError={setErr}
          />
          <TextInput
            name={"Email"}
            formName={"email"}
            data={adminData}
            handleInput={handleAdminData}
            setError={setErr}
          />
        </div>
        <div className={`flex w-full h-fit justify-between gap-5`}>
          <TextInput
            name={"Department"}
            formName={"department"}
            data={adminData}
            handleInput={handleAdminData}
            setError={setErr}
          />
          <TextInput
            name={"Category"}
            formName={"category"}
            data={adminData}
            handleInput={handleAdminData}
            setError={setErr}
          />
        </div>
        <div
          className={`${
            editData?.is_master ? "hidden" : "flex"
          } w-full h-fit flex-col gap-1.5`}
        >
          {pages.length > 0 && (
            <div className="flex w-full gap-1 flex-wrap">
              {pages?.map((item, idx) => (
                <div
                  className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                  key={idx}
                >
                  <span>{item}</span>
                  <button
                    onClick={() => {
                      setPages(pages.filter((page) => page !== item));
                    }}
                  >
                    <AiOutlineCloseCircle fontSize={14} color="red" />
                  </button>
                </div>
              ))}
            </div>
          )}
          <label htmlFor={"pages"} className="text-[14px] text-shadow-black">
            {"Pages"} <span className="text-red-600">*</span>
          </label>
          <select
            className="appearance-none w-full bg-white border text-[14px] rounded py-2 px-4 outline-none shadow-md"
            value={pages[pages.length]}
            onChange={handleSelectedPages}
          >
            {currentPages?.map((page, index) => (
              <option key={index} value={page}>
                {page}
              </option>
            ))}
          </select>
        </div>
        <div
          className={`${
            editData?.is_master ? "hidden" : "flex"
          }  w-full h-fit flex-col gap-1.5`}
        >
          {access.length > 0 && (
            <div className="flex w-full gap-1 flex-wrap">
              {access?.map((item, idx) => (
                <div
                  className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                  key={idx}
                >
                  <span>{item}</span>
                  <button
                    onClick={() => {
                      setAccess(access.filter((acces) => acces !== item));
                    }}
                  >
                    <AiOutlineCloseCircle fontSize={14} color="red" />
                  </button>
                </div>
              ))}
            </div>
          )}
          <label htmlFor={"access"} className="text-[14px] text-shadow-black">
            {"Access"} 
          </label>
          <select
            className="appearance-none w-full bg-white border text-[14px] rounded py-2 px-4 outline-none shadow-md"
            value={access[access.length]}
            onChange={handleAccess}
          >
            {currentAccess?.map((page, index) => (
              <option key={index} value={page}>
                {page}
              </option>
            ))}
          </select>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <ShowImage image={image} setImage={setImage} />
          <ImageInput imageName={"Admin Image"} setImage={setImage} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAdminModal