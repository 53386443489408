import { axiosAuthorized } from "../../api/config"

export const getDoctors = async () => {
    try {
        const response = await axiosAuthorized.get("/doctor/get_doctor");
        return response;
    } catch (error) {
        throw error;
    }
}


export const addDoctor = async (initialState, department_id, degreeData, specialities, profileImage, coverImage,meta_tag, doctorId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            age: initialState.age,
            gender: initialState.gender,
            department_id,
            experience: initialState.experience,
            qualification: degreeData,
            speciality: specialities,
            doctor_image: profileImage,
            doctor_cover_image: coverImage,
            email: initialState.email,
            phone_number: initialState.phone,
            meta_name: initialState.meta_name,
            meta_description: initialState.meta_description,
            meta_tag
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = doctorId
            ? `/doctor/update_doctor/${doctorId}`
            : `/doctor/add_doctor`;

        const response = await axiosAuthorized[doctorId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteDoctor = async (doctorId) => {
    try{
        const response = await axiosAuthorized.delete(`/doctor/delete_doctor/${doctorId}`);
        return response;
    } catch (error) {
        throw error;
    }
}