import React, { useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import {
  AddButton,
  ImageInput,
  NumberInput,
  ShowImage,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {  validateMemberForm } from "../../utils/aboutUsUtils/validateAboutUsForm";
import { toast } from "react-toastify";
import { addMember } from "../../utils/aboutUsUtils/aboutUsUtils";

const AboutUsModal = ({ setOpen, editData = null, setEditData, setAboutUsFn }) => {
  const initialState = {
    name: editData?.name || "",
    role: editData?.role || "",
    experience: editData?.experience || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] = useForm(metaTagInitialState);
  const [err, setError] = useState({ global: "" });
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [profile_picture, setProfileImage] = useState(editData?.profile_picture || null);
  const [past_company_logo, setPastCompanyLogo] = useState(editData?.past_company_logo || null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateMemberForm(formData,profile_picture,past_company_logo,setError)){
        setError({ global: "" });
        setLoading(true);
        addMember(
          formData,
          profile_picture,
          past_company_logo,
          "Our Team",
          metaTags,
          editData?._id
        )
          .then((res) => {
            clearForm();
            setProfileImage(null);
            setPastCompanyLogo(null);
            toast(`Member ${editData ? "Edited" : "Added"}`, {
              type: "success",
              autoClose: 3000,
            });
            setEditData(null);
            setAboutUsFn();
            setOpen(false);
          })
          .catch((err) => {
            toast(`Member ${editData ? "edit" : "add"} failed`, {
              type: "error",
              autoClose: 3000,
            });
          })
          .finally(() => {
            setLoading(false);
          });
    }
  };
  return (
    <div className="modal-container min-h-[400px] z-10">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Member" : "Add New Member"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              clearForm();
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Name"}
            formName={"name"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Role"}
            formName={"role"}
            setError={setError}
          />
          <NumberInput
            data={formData}
            handleInput={handleInput}
            name={"Experience"}
            formName={"experience"}
            setError={setError}
          />
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage image={profile_picture} setImage={setProfileImage} />
              <ImageInput
                image={profile_picture}
                setImage={setProfileImage}
                imageName={"Profile Image"}
              />
            </>
          }
        </div>
        <div className="feild-conatainer">
          {
            <>
              <ShowImage
                image={past_company_logo}
                setImage={setPastCompanyLogo}
              />
              <ImageInput
                image={past_company_logo}
                setImage={setPastCompanyLogo}
                imageName={"Past Company Image"}
              />
            </>
          }
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                clearForm();
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsModal;
