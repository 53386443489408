import React, { useRef, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { addPolicy } from "../../utils/privacyUtils/privacyUtils";
import JoditEditor from "jodit-react";

const config = { readonly: false, placeholder: "Description" };
const PrivacyAndPolicyModal = ({
  setOpen,
  editData = null,
  setEditData,
  setDatasFn,
}) => {
  const [err, setError] = useState({ global: "" });
  const editor = useRef(null);
  const [description, setDescription] = useState(editData?.description || "<p></p>")
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!description.replace(/<[^>]*>/g, "").trim().length) return setError({ global: "Invalid Description" })
      setError({ global: "" });
      setLoading(true);
      addPolicy(description, "Privacy Policy", editData?._id)
        .then((res) => {
          toast(`Privacy And Policy ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setEditData(null);
          setDatasFn();
          setOpen(false);
        })
        .catch((err) => {
          toast(`Privacy And Policy ${editData ? "edit" : "add"} failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
  };
  return (
    <div className="modal-container min-h-[400px] z-10">
      <div className="modal">
        <div className="modal-header">
          <span>Privacy And Policy</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex flex-col w-full gap-0.5">
          <label htmlFor={"Description"} className="label">
            {"Description"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={description}
            config={config}
            tabIndex={1}
            onBlur={(newContent) =>
              setDescription(
                newContent.replace(
                  /<td(?:[^>]*)style="[^"]*"(?:[^>]*)>/g,
                  '<td style="border: 1px solid black;" >'
                )
              )
            }
          />
        </div>
        {err.global && <div className="error-message">{err.global}</div>}
        <div className="feild-conatainer">
          <div className="lable-and-filed">
            <button
              className="cancle-button"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="lable-and-filed">
            <button
              className="submit-button"
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndPolicyModal;
