export const validateSymptomForm = (initialState, treatId, image, setError) => {
    if (!initialState.name.trim().length) {
        setError({ global: "Invalid Name" });
        return false;
    }
    if (treatId === 0) {
        setError({ global: "Please Select Treatment" });
        return false;
    }
    if (!image) {
        setError({ global: "Select Image" });
        return false;
    }

    return true;
};
