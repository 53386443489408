import React from "react";
import "./NumberInput.css";

const NumberInput = ({ name="",formName, data, handleInput,hide=false, setError, min=1, max=null }) => {
  return (
    <div className="label-and-field">
      {name.length > 0 && <label htmlFor={name} className="label">
        {name} <span hidden={hide} className="text-red-600">*</span>
      </label>}
      <div className="input-container" onClick={() => setError("")}>
        <input
          type="number" 
          name={formName}
          id={formName}
          value={data[formName]}
          onChange={handleInput}
          placeholder={name}
          className="input"
          min={min} 
          max={max}
        />
      </div>
    </div>
  );
};

export default NumberInput;
