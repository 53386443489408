import { axiosAuthorized } from "../../api/config";

export const getInsuranceProviders = async () => {
    try {
        const response = await axiosAuthorized.get("/insurance/get_insuranceslider");
        return response;
    } catch (error) {
        throw error;
    }
}

export const addInsuranceProvider = async (initialState, image, insuranceProviderId = null) => {
    try {
        const requestData = {
            name: initialState.name,
            link: initialState.link,
            insurance_image: image
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = insuranceProviderId
            ? `/insurance/update_insuranceslider/${insuranceProviderId}`
            : `/insurance/add_insuranceslider`;

        const response = await axiosAuthorized[insuranceProviderId ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteInsuranceProvider = async (insuranceProviderId) => {
    try {
        const response = await axiosAuthorized.delete(`/insurance/delete_insuranceslider/${insuranceProviderId}`);
        return response;
    } catch (error) {
        throw error;
    }
}