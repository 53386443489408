import React, { useCallback, useEffect, useState } from "react";
import "./TreatmentsMainSection.css";
import { SearchInput } from "../Common";
import { LiaStethoscopeSolid } from "react-icons/lia";
import TreatmentInformation from "./TreatmentsTable";
import TreatmentModal from "./TreatmentModal";
import { getTreatments } from "../../utils/treamentUtils/treatmentUtils";
import { useSelector } from "react-redux";

const TreatmensMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [treatments, setTreatments] = useState([]);
  const setTreatmentFn = useCallback(() => {
    getTreatments()
      .then((res) => setTreatments(res.data.data))
      .catch((err) => console.log(err));
  },[]);
  useEffect(() => {
      setTreatmentFn();
  }, [setTreatmentFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTreatments, setFilteredTreatments] = useState([]);
  useEffect(() => {
    const filteredData = treatments?.filter((treatment) => {
      const nameMatch = treatment?.sub_department_name?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch ;
    });
    setFilteredTreatments(filteredData);
  }, [treatments, searchQuery]);
  const user = useSelector((state) => state.userReducer.admin);
  return (
    <>
      {open && (
        <TreatmentModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setTreatmentFn={setTreatmentFn}
        />
      )}
      <div className="tp-header">
        <div className="flex w-fit h-fit text-xl font-semibold">Treatments</div>
        <div className="tp-header-search-container">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, Department"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="tp-info-container">
        <div className="tp-info-header">
          <span className="font-semibold text-base">Informations</span>
          <button
            disabled={!user.access.includes("Edit")}
            className={`treatment-add-button ${
              !user.access?.includes("Edit") ? "hidden" : "flex"
            }`}
            onClick={() => setOpen(true)}
          >
            <span>
              <LiaStethoscopeSolid />
            </span>
            <span>Treatment</span>
          </button>
        </div>
        <TreatmentInformation
          user={user}
          setEditData={setEditData}
          setOpen={setOpen}
          treatments={filteredTreatments}
          setTreatmentFn={setTreatmentFn}
        />
      </div>
    </>
  );
};

export default TreatmensMainSection;
