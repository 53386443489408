import React, { useState } from 'react'
import { useForm } from '../../hooks';
import { TextInput } from '../Common/FormComponents';
import { GrFormClose } from 'react-icons/gr';
import { toast } from 'react-toastify';
import { addTestominal } from '../../utils/testominalUtils/testomnialUtils';

const TestominalModal = ({ setOpen, editData = null, setEditData, setTestominalsFn }) => {
    const testominalInitialState = {
      video_link: editData?.video_link || "",
    };
    const [testominalData, handleTestominalData, clearForm] = useForm(testominalInitialState);
    const [err, setErr] = useState({ global: "" });
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (testominalData.video_link.trim().length < 2) return setErr({ global: "Invalid Link" });
        setLoading(true);
        addTestominal(testominalData, editData?._id)
        .then((result) => {
            setTestominalsFn();
            clearForm();
            toast(`Link ${editData ? "Edited" : "Added"}`, {
              type: "success",
              autoClose: 3000,
            });
            setEditData(null);
            setOpen(false);
        }).catch((err) => {
          console.log(err);
          toast(`Link ${editData ? "edit failed" : "add failed"}`, {
            type: "success",
            autoClose: 3000,
          });
        }).finally(()=>setLoading(false));
    };
  return (
    <div className="flex bg-white   top-0 left-0 w-full max-w-screen-sm overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
      <div className="flex w-full h-fit  items-center justify-between font-semibold">
        <span>{"Add new Tag"}</span>
        <span
          className="cursor-pointer"
          onClick={() => {
            setOpen(false);
            setEditData(null);
          }}
        >
          <GrFormClose fontSize={16} />
        </span>
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name={"Video Link"}
          formName={"video_link"}
          data={testominalData}
          handleInput={handleTestominalData}
          setError={setErr}
        />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            Cancel
          </button>
        </div>
        <div className="flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
            disabled={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default TestominalModal