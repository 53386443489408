import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  AddButton,
  SelectInput,
  TextAreaInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addMetaTitleDescriptionTags } from "../../utils/homeUtils/metatitledescriptiontagsUtils";

const MetaTitleDescriptionTagsModal = ({
  setOpen,
  setDatasFn,
  editData = null,
  setEditData,
}) => {
  const [err, setErr] = useState({ global: "" });
  const [meta_tags, setTags] = useState(editData?.meta_tags || []);
  const tagsInitailsState = { tag: "" };
  const [tagFormData, handleTagFromData, clearTagFormData] =
    useForm(tagsInitailsState);
  const handleButtons = (action, idx = 0) => {
    if (action === "add") {
      if (!tagFormData.tag.trim().length) {
        return setErr({ global: "Please Enter Tag" });
      } else {
        setTags([...meta_tags, tagFormData.tag]);
        clearTagFormData();
      }
    } else if (action === "remove") {
      setTags(meta_tags.filter((_, index) => index !== idx));
    }
  };
  const [loading, setLoading] = useState(false);
  const initialsState = {
    meta_title: editData?.meta_title || "",
    meta_description: editData?.meta_description || "",
    type: editData?.type || "Select Page",
  };
  const [formData, handleFormData, clearForm] = useForm(initialsState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.meta_title.trim().length)
      return setErr({ global: "Invalid Title" });
    if (formData.type === "Select Page")
      return setErr({ global: "Please Select Page" });
    if (!formData.meta_description.trim().length)
      return setErr({ global: "Invalid Description" });
    setErr({ global: "" });
    setLoading(true);
    addMetaTitleDescriptionTags(formData, meta_tags, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        clearForm();
        setEditData(null);
        toast(`Meta Informations ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        console.error(err);
        toast(`Meta Informations ${editData ? "Edit Failed" : "Add Failed"}`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
      <div
        className=" flex bg-white max-w-md w-full overflow-y-auto max-h-[400px] border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{"Meta Title Description Tags"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col ">
          <TextInput
            data={formData}
            formName={"meta_title"}
            name="Meta Title"
            handleInput={handleFormData}
            setError={setErr}
          />
          <SelectInput
            data={formData}
            items={[
              "Select Page",
              "Overview",
              "About",
              "Doctors",
              "Labs",
              "Pharmacy",
              "Blogs",
              "Careers",
              "ContactUs",
            ]}
            formName={"type"}
            name="Page"
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextAreaInput
            data={formData}
            formName={"meta_description"}
            name="Meta Description"
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {meta_tags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {meta_tags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs "
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleButtons("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            data={tagFormData}
            handleInput={handleTagFromData}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setErr}
          />
          <AddButton handleAdd={handleButtons} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setEditData(null);
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaTitleDescriptionTagsModal;
