import React from 'react'
import { BlogsMainSection } from '../../components/BlogsComponents';

const Blogs = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <BlogsMainSection/>
    </div>
  );
}

export default Blogs