import React, { useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import { AddButton, ImageInput, ShowImage, TextAreaInput, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { validateIconForm } from "../../utils/aboutUsUtils/validateAboutUsForm";
import { addIcon } from "../../utils/aboutUsUtils/aboutUsUtils";
import { AiOutlineCloseCircle } from "react-icons/ai";
const ProblemsWeAreSolvingModal = ({
  setOpen,
  editData = null,
  setEditData,
  setProblemsWeAreSolvingFn,
}) => {
  const initialState = {
    icon_name: editData?.icon_name || "",
    meta_name: editData?.meta_name || "",
    meta_description: editData?.meta_description || "",
  };
  const metaTagInitialState = { tag: "" };
  const [metaFormData, handleMetaForm, clearMetaForm] = useForm(metaTagInitialState);
  const [err, setError] = useState({ global: "" });
  const [metaTags, setMetaTags] = useState(editData?.meta_tag || []);
  const handleMetaTags = (action, idx = 0) => {
    if (action === "add") {
      if (!metaFormData.tag.trim().length) {
        return setError({ global: "Please Enter Tag" });
      } else {
        setMetaTags([...metaTags, metaFormData.tag]);
        clearMetaForm();
      }
    } else if (action === "remove") {
      setMetaTags(metaTags.filter((_, index) => index !== idx));
    }
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(editData?.icon || null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateIconForm(formData,image,setError)){
        setError({ global: "" });
        setLoading(true);
        addIcon(
          formData,
          image,
          "Problems We are solving",
          metaTags,
          editData?._id
        )
          .then((res) => {
            clearForm();
            setImage(null);
            toast(`Problem ${editData ? "Edited" : "Added"}`, {
              type: "success",
              autoClose: 3000,
            });
              setEditData(null);
            setProblemsWeAreSolvingFn();
            setOpen(false);
          })
          .catch((err) => {
            toast(`Problem ${editData ? "edit" : "add"} failed`, {
              type: "error",
              autoClose: 3000,
            });
          })
          .finally(() => {
            setLoading(false);
          });
    }
  };
  return (
    <div className="modal-container  min-h-[400px] z-10">
      <div className="modal">
        <div className="modal-header">
          <span>{editData ? "Edit Problem" : "Add New Problem"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Title"}
            formName={"icon_name"}
            setError={setError}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          {
            <>
              <ShowImage image={image} setImage={setImage} />
              <ImageInput
                image={image}
                setImage={setImage}
                imageName={"Problem Image"}
              />
            </>
          }
        </div>
        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Name"}
            formName={"meta_name"}
            setError={setError}
          />
          <TextAreaInput
            hide={true}
            data={formData}
            handleInput={handleInput}
            name={"Meta Description"}
            formName={"meta_description"}
            setError={setError}
          />
        </div>
        {metaTags?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {metaTags?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleMetaTags("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer">
          <TextInput
            hide={true}
            data={metaFormData}
            handleInput={handleMetaForm}
            name={"Meta Tag"}
            formName={"tag"}
            setError={setError}
          />
          <AddButton handleAdd={handleMetaTags} />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProblemsWeAreSolvingModal;
