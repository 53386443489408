export const validateWhyLifePlus = (initialState, setError) => {

    if (!initialState.title.trim().length) {
        setError({ global: "Invalid Title" });
        return false;
    }

    if (!initialState.description.trim().length) {
        setError({ global: "Invalid Description" });
        return false;
    }
    return true;
}