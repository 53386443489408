import React, { useCallback, useEffect, useState } from "react";
import "./DepartmentsMainSection.css";
import { SearchInput } from "../Common";
import { CiHospital1 } from "react-icons/ci";
import DepartmentsTable from "./DepartmenstTable";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import AddAndEditDepartmentModal from "./AddAndEditDepartmentModal";
import { useSelector } from "react-redux";

const DepartmentsMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [departments, setDepartments] = useState([]);
    const setDepartmentsFn = useCallback(() => {
      getDepartments()
        .then((res) => setDepartments(res.data.data))
        .catch((err) => console.log(err));
  },[]);
  useEffect(() => {
    setDepartmentsFn();
  }, [setDepartmentsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDepartments, setFileteredDepartments] = useState([]);
  useEffect(() => {
    const filteredData = departments?.filter((department) => {
      const nameMatch = department?.department?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch;
    });
    setFileteredDepartments(filteredData);
  }, [departments, searchQuery]);
  const user = useSelector((state) => state.userReducer.admin);
  return (
    <>
      {open && (
        <AddAndEditDepartmentModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setDepartmentsFn={setDepartmentsFn}
        />
      )}
      <div className="department-header">
        <div className="flex w-fit h-fit text-xl font-semibold">
          Departments
        </div>
        <div className="department-header-search-container">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search By Name, Description"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="department-info-container">
        <div className="department-info-header">
          <span className="font-semibold text-base">Informations </span>
          <button
            disabled={!user.access.includes("Edit")}
            className={`department-add-button  ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            }`}
            onClick={() => setOpen(true)}
          >
            <span>
              <CiHospital1 />
            </span>
            <span>Department</span>
          </button>
        </div>
        <DepartmentsTable
          setEditData={setEditData}
          setOpen={setOpen}
          user={user}
          departments={filteredDepartments}
          setDepartmentsFn={setDepartmentsFn}
        />
      </div>
    </>
  );
};

export default DepartmentsMainSection;
