import React from "react";
import "./TextAreaInput.css";

const TextAreaInput = ({ name, formName, data, handleInput, setError, hide=false }) => {
  return (
    <div className="label-and-field w-full">
      <label htmlFor={name} className="label">
        {name} <span hidden={hide} className="text-red-600">*</span>
      </label>
      <div className="input-coantiner" onClick={() => setError({ global: "" })}>
        <textarea
          name={formName}
          id={formName}
          value={data[formName]}
          onChange={handleInput}
          placeholder={name}
          className="input min-h-[120px]"
        />
      </div>
    </div>
  );
};

export default TextAreaInput;
