import React, { useState } from 'react'
import DoctorSection from './DoctorSection';
import LabSection from './LabSection';
import PatientsSection from './PatientsSection';
import WebsiteSection from './WebsiteSection';
import MostVisitedDepartments from './MostVisitedDepartments';
import CommonSymptoms from './CommonSymptoms';
import PatientsDataSection from './PatientsDataSection';
import DoctorDataSection from './DoctorDataSection';
import LabDataSection from './LabDataSection';
const AnalyticsMainSection = () => {
    const [selected, setSelected] = useState(1);
  return (
    <>
      <div className="flex w-full justify-start items-center gap-x-3">
        <div className="flex gap-3">
          <button
            onClick={() => setSelected(1)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 1 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Website
          </button>
          <button
            onClick={() => setSelected(2)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 2 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Patients
          </button>
          <button
            onClick={() => setSelected(3)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 3 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Doctors
          </button>
          <button
            onClick={() => setSelected(4)}
            className={`flex px-3 border border-primary py-1 rounded-md ${
              selected === 4 ? "bg-primary text-white" : "bg-white text-primary"
            }`}
          >
            Labs
          </button>
        </div>
      </div>
      {selected === 1 && <WebsiteSection />}
      {selected === 2 && <PatientsSection />}
      {selected === 2 && <PatientsDataSection />}
      {selected === 3 && <DoctorSection />}
      {selected === 3 && <DoctorDataSection />}
      {selected === 4 && <LabSection />}
      {selected === 4 && <LabDataSection />}
      <hr className="m-5" />
      <MostVisitedDepartments />
      <hr className="m-5" />
      <CommonSymptoms/>
    </>
  );
}

export default AnalyticsMainSection