import React, { useState } from "react";
import { useForm } from "../../hooks";
import { GrFormClose } from "react-icons/gr";
import {
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import {  addNumber } from "../../utils/aboutUsUtils/aboutUsUtils";
const NumbersModal = ({
  setOpen,
  editData = null,
  setEditData,
  setNumbersFn,
}) => {
  const initialState = {
    life_plus_title: editData?.life_plus_title || "",
    life_plus_number: editData?.life_plus_number || "",
  };
  const [err, setError] = useState({ global: "" });
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.life_plus_title.trim().length) {
      setError({ global: "Please enter title" });
      return;
    }
    if (!formData.life_plus_number.trim().length) {
      setError({ global: "Please enter number" });
      return;
    }
    setError({ global: "" });
    setLoading(true);
    addNumber(formData, editData?._id)
      .then((res) => {
        clearForm();
        toast(`Number ${editData ? "Edited" : "Added"}`, {
          type: "success",
          autoClose: 3000,
        });
        setEditData(null);
        setNumbersFn();
        setOpen(false);
      })
      .catch((err) => {
        toast(`Number ${editData ? "edit" : "add"} failed`, {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="modal-container min-h-[200px] z-10">
      <div className="modal h-full">
        <div className="modal-header">
          <span>{editData ? "Edit Number" : "Add New Number"}</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Title"}
            formName={"life_plus_title"}
            setError={setError}
          />
          <TextInput
            data={formData}
            handleInput={handleInput}
            name={"Number"}
            formName={"life_plus_number"}
            setError={setError}
          />
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumbersModal;
