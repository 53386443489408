import React, {  useCallback, useEffect, useState } from 'react'
import { SearchInput } from '../Common';
import { BiTestTube } from 'react-icons/bi';
import LabsTable from './LabsTable';
import LabModal from './LabModal';
import { getLabs } from '../../utils/labUtils/labUtils';
import { useSelector } from 'react-redux';

const LabsMainSection = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [labs, setLabs] = useState([]);
    const setLabsFn = useCallback(() => {
      getLabs()
        .then((res) => setLabs(res.data.data))
        .catch((err) => console.log(err));
  },[]);
  useEffect(() => {
    setLabsFn();
  }, [setLabsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdLabs, setFilteredLabs] = useState([]);
  useEffect(() => {
    const filteredData = labs?.filter((lab) => {
      const nameMatch = lab?.name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const ownerMatch = lab?.owner_name?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const phoneMatch = lab?.phone_number.toString()?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      const emailMatch = lab?.email_id?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());
      return nameMatch || ownerMatch || phoneMatch || emailMatch;
    });
    setFilteredLabs(filteredData);
  }, [labs, searchQuery]);
  const user = useSelector((state) => state.userReducer.admin);
    return (
      <>
        {open && (
          <LabModal
            setOpen={setOpen}
            editData={editData}
            setEditData={setEditData}
            setLabsFn={setLabsFn}
          />
        )}
        <div className=" flex w-full items-center gap-5 md:gap-0">
          <div className="flex w-fit h-fit text-xl font-semibold">Labs</div>
          <div className="flex w-full h-full justify-center items-center">
            <div className="flex w-full md:w-1/2">
              <SearchInput
                placeholder={"Search by Lab name, Owner name, Phone, Email"}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full p-3 flex-col">
          <div className="flex w-full justify-between items-center h-fit">
            <span className="font-semibold text-base">Informations</span>
            <button
              disabled={!user.access?.includes("Edit")}
              className={`bg-secondary text-white text-sm rounded-md gap-2 p-2 active:bg-secondary items-center ${
              !user.access.includes("Edit") ? "hidden" : "flex"
            }`}
              onClick={() => setOpen(true)}
            >
              <span>
                <BiTestTube />
              </span>
              <span>Lab</span>
            </button>
          </div>
          <LabsTable
            user={user}
            setOpen={setOpen}
            setEditData={setEditData}
            labs={filterdLabs}
            setLabsFn={setLabsFn}
          />
        </div>
      </>
    );
}

export default LabsMainSection