import React, { useState } from 'react'
import { useForm } from '../../hooks';
import { toast } from 'react-toastify';
import { GrFormClose } from 'react-icons/gr';
import { TextAreaInput, TextInput } from '../Common/FormComponents';
import { addWhyLifePlus } from '../../utils/whyLifePlusUtils/whyLifePlusUtils';
import { validateWhyLifePlus } from '../../utils/whyLifePlusUtils/validateWhyLifePlus';

const WhyLifeSectionModal = ({ setOpen, editData, setEditData, setWhyLifePlusFn }) => {
  const whyLifePlusInitialState = {
    title: editData?.title || "",
    description: editData?.description || "",
  };
  const [whyLIfePlusData, handlewhyLIfePlusData, clearForm] = useForm(whyLifePlusInitialState);
  const [err, setErr] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateWhyLifePlus(whyLIfePlusData, setErr)) {
      setErr({ global: "" });
      setLoading(true);
      addWhyLifePlus(whyLIfePlusData, editData?._id)
        .then((res) => {
          setWhyLifePlusFn();
          clearForm();
          toast(`Description ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
          toast(`Description ${editData ? "edit failed" : "add failed"}`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <div className="flex bg-white   top-0 left-0 w-full max-w-screen-sm overflow-y-auto h-full border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col">
      <div className="flex w-full h-fit  items-center justify-between font-semibold">
        <span>{"Add new Faq"}</span>
        <span
          className="cursor-pointer"
          onClick={() => {
            setOpen(false);
            setEditData(null);
          }}
        >
          <GrFormClose fontSize={16} />
        </span>
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name={"Title"}
          formName={"title"}
          data={whyLIfePlusData}
          handleInput={handlewhyLIfePlusData}
          setError={setErr}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextAreaInput
          name={"Description"}
          formName={"description"}
          data={whyLIfePlusData}
          handleInput={handlewhyLIfePlusData}
          setError={setErr}
        />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            Cancel
          </button>
        </div>
        <div className="flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
            disabled={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhyLifeSectionModal