import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from '../../hooks';
import {   MapFaqs, TextAreaInput, TextInput } from '../Common/FormComponents';
import {  addFaq, deleteFaq, getFaqs } from '../../utils/treamentUtils/treatmentUtils';
import { validateFaqForm } from '../../utils/treamentUtils/validateFaqForm';

const FaqSection = ({selectedTreatment}) => {
    const faqsInitialState = {
      title: "",
      answer: ""
    };
    const [faqs,setFaqs] = useState([]);
    const setFaqsFn = useCallback(() => {
        getFaqs(selectedTreatment?._id).then((res) => setFaqs(res.data.data)).catch(err=>console.log(err));
    }, [selectedTreatment?._id]);
    useEffect(() => {
      setFaqsFn();
    }, [setFaqsFn]);
    const remvoeFaq = (faqId) => {
    deleteFaq(faqId).then(res => setFaqsFn()).catch(err => console.log(err));
    };
    const [faqData, handleFaqData, clearFaqData] = useForm(faqsInitialState);
    const [err,setErr] = useState({faq:""})
    const [loading, setLoading] = useState(false);
        const handleSubmit = (e) => {
        e.preventDefault();
            if (validateFaqForm(faqData, setErr)) {
            setLoading(true);
            setErr({ faq: "" });
            addFaq(faqData, selectedTreatment?._id)
                .then(res => { clearFaqData(); setFaqsFn() })
                .catch(err => { console.log(err) })
                .finally(()=>{ setLoading(false);});
        }
    }
  return (
    <div className='flex flex-col gap-4'>
        <div className="flex w-full h-fit items-center justify-between font-semibold">
          <span>{"Add Faqs"}</span>
      </div>
        <MapFaqs  faqs={faqs} removeFaq={remvoeFaq}/>
        <div className="feild-conatainer">
          <TextInput data={faqData} handleInput={handleFaqData} name={"Question"} formName={"title"} setError={setErr}/>
      </div>
        <div className="feild-conatainer">
          <TextAreaInput data={faqData} handleInput={handleFaqData} name={"Answer"} formName={"answer"} setError={setErr}/>
          <div className="flex bg-slate-200 rounded-md w-fit h-fit">
          <button disabled={loading}  className='p-1 border rounded-md -translate-y-1 active:-translate-y-0 translate-x-1 active:translate-x-0 duration-200 font-semibold bg-white text-xs' onClick={(e)=>{handleSubmit(e)}}>Add</button>
        </div>
        </div>
        {err.faq && <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">{err.faq}</div>}
    </div>
  )
}

export default FaqSection

